import { useLocation, useNavigate } from "react-router-dom";
import "./Flexibleframes.scss";

import React from "react";

export default function Flexibleframes({ homeData }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={` ${
        location.pathname.includes("retailer") ? "" : ""
      } Flexible_Frames_Container`}
      style={{
        backgroundImage: `url(${homeData?.promotion_banner?.background})`,
      }}
    >
      <div className="Section_title_container">
        <div className="section-title font-fc-mont">
          {homeData?.promotion_banner?.title}
        </div>
        <div className="Section_Sub_title font-fc-sabon text-center">
          {homeData?.promotion_banner?.subtitle}
        </div>
      </div>
      <div
        onClick={() => navigate(homeData?.promotion_banner?.redirect)}
        className="try-now-btn mont-regular text-nowrap"
      >
        {homeData?.promotion_banner?.button_text}
        <div className="overlay"></div>
      </div>
    </div>
  );
}
