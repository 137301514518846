import "swiper/css";
import "./browseglasses.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";

import { Breakpoint } from "react-socks";
import React from "react";
import { Link } from "react-router-dom";

function BrowseGlasses({ homeData }) {
  return (
    <div className="BrowseGlasses">
      <div className="font-sabon text-color heading lg:mb-0 emd:mb-5 small:mb-5">
        {homeData?.curated_glasses?.title}
      </div>
      <div className="d-flex g-10">
      </div>
      <Breakpoint large up>
        <div className="slider">
          {homeData?.curated_glasses?.banners?.map((item, index) => {
            return (
              <Link
                key={index}
                to={item?.redirect}
                className="slider-item"
                style={{ position: "relative" }}
              >
                  <img
                    className="img glass-img "
                    src={item?.image}
                    alt="slider  1"
                    style={{
                      // position: "absolute",
                      zIndex: "999",
                      objectFit: "contain",
                      paddingBottom:"10%",
                      paddingLeft:"5px",
                      paddingRight:"5px",
                    }}
                  />
                <img className="img" src={item?.box_img} alt="slider  1" />
                <img
                  className="img-back"
                  src={item?.background}
                  alt="slider  1"
                  style={{ objectFit: "contain" }}
                />
                <div className="overlay"></div>
                <div className="text mont-regular text-nowrap">{item?.button_text}</div>
              </Link>
            );
          })}
        </div>
      </Breakpoint>

      <Breakpoint medium down>
        <div className="slider">
          <Swiper
            slidesPerView={1.2}
            spaceBetween={20}
            centeredSlides={true}
            initialSlide={1}
            pagination={{
              clickable: false,
            }}
            modules={[]}
            className="mySwiper"
          >
            {homeData?.curated_glasses?.banners?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="slider-item " style={{ position: "relative" , width:"100%" }}>
                      <img
                        className="img glass-img"
                        src={item?.image}
                        alt="slider  1"
                        style={{
                          position: "absolute",
                          zIndex: "99",
                          objectFit: "contain",
                          padding:"10%",
                          paddingBottom:"15%"
                        }}
                      />
                    <img className="img" src={item?.box_img} alt="slider  1" />
                    <img
                      className="img-back object-cover"
                      src={item?.background}
                      alt="sliderimage1"
                    />
                    <div className="overlay"></div>
                    <Link to={item?.redirect} className="text mont-regular text-nowrap">
                      {item?.button_text}
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </Breakpoint>
    </div>
  );
}

export default BrowseGlasses;
