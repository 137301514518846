import "./App.scss";

import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import Home from "./b2b/home/Home";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { lazy } from "react";
import Header from "./b2b/header/Header";
import Footer, { isValidEmail } from "./b2b/Footer/Footer";

import Favorites from "./b2b/Pages/Favorites/Favorites";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginSignup from "./auth/LoginSignup";
import {
  useCheckPaymentStatusMutation,
  useGetCouponListQuery,
  useGetCustomerBagDataQuery,
  useGetCustomerHomeTryOnDataQuery,
  useGetCustomerWishlistQuery,
  useGetHeaderLabelsDataQuery,
  useSubscribeMutation,
} from "./redux/slices/homeSlice";
import { setWishlist } from "./redux/slices/wishlistSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import ProductPage from "./b2b/Pages/Favorites/ProductPage/ProductPage";
import Category from "./b2b/Pages/Category/Category";
import {
  addToMyBag,
  setMyBag,
  setOrderDetails,
} from "./redux/slices/myBagSlice";
import MyBag from "./b2b/Pages/MyBag/MyBag";
import ShoppingBag from "./b2b/Pages/ShoppingBag/ShoppingBag.jsx";
import ShoppingTray from "./b2b/Pages/ShoppingTray/ShoppingTray.jsx";
import OrderPlaced from "./b2b/Pages/OrderPlaced/OrderPlaced.jsx";
import ProfileDetails from "./b2b/Pages/ProfileDetails/ProfileDetails.jsx";
import BTN from "./b2b/Master/BTN.jsx";
import INPUT from "./b2b/Master/INPUT.jsx";
import Sidebar from "./b2b/header/Sidebar.jsx";
import Lens from "./b2b/Pages/Lens/Lens.jsx";
import TrackOrder from "./b2b/Pages/ProfileDetails/TrackOrder.jsx";
import ExchangeOrder from "./b2b/Pages/ProfileDetails/ExchangeOrder.jsx";
import CancelOrder from "./b2b/Pages/ProfileDetails/CancelOrder.jsx";
import ReturnOrder from "./b2b/Pages/ProfileDetails/ReturnOrder.jsx";
import ContactUs from "./b2b/Pages/ContactUs/ContactUs.jsx";
import BecomeRetailer from "./b2b/Pages/ContactUs/BecomeRetailer.jsx";
import Retailer from "./b2b/Pages/Retailer/Retailer.jsx";
import "react-loading-skeleton/dist/skeleton.css";
import RetailerHeader from "./b2b/header/RetailerHeader.jsx";
import AboutUs from "./b2b/Pages/BrandInfo/AboutUs.jsx";
import PrivacyPolicy from "./b2b/Pages/BrandInfo/PrivacyPolicy.jsx";
import TermsCondition from "./b2b/Pages/BrandInfo/TermsCondition.jsx";
import ExchangePolicy from "./b2b/Pages/BrandInfo/ExchangePolicy.jsx";
import ReturnPolicy from "./b2b/Pages/BrandInfo/ReturnPolicy.jsx";
import OrderFailed from "./b2b/Pages/OrderPlaced/OrderFailed.jsx";
import {
  PAGE_NOT_FOUND,
  TIMER_PAGE_GIRL,
  TIMER_PAGE_SKYE,
} from "./assets/index.js";
import Skeleton from "react-loading-skeleton";
import { useFormik } from "formik";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { Breakpoint } from "react-socks";
import { setHomeTryOn } from "./redux/slices/homeTryonSlice.js";
import HTO from "./b2b/Pages/HTO/HTO.jsx";
import Accessories from "./b2b/Pages/Accessories/Accessories.jsx";
function App() {
  const location = useLocation();
  const [sidebarToggle, setSidebarToggle] = useState(false);
  // lens screen steps
  const [lensStep, setLensStep] = useState(1);
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);
  const {
    data: headerLabelsData,
    isSuccess: headerLabelsSuccess,
    isLoading: headerLabelsLoading,
    isError: headerLabelsIsError,
    error: headerLabelsError,
  } = useGetHeaderLabelsDataQuery();

  const {
    data: wishlistData,
    isSuccess: wishlistSuccess,
    isLoading: wishlistLoading,
    isError: wishlistIsError,
    error: wishlistError,
  } = useGetCustomerWishlistQuery({ user: user }, { skip: !user });

  useEffect(() => {
    if (wishlistSuccess) {
      const data = wishlistData.wishlist;
      dispatch(setWishlist(data));
    } else if (wishlistIsError) {
      dispatch(setWishlist([]));
    }
  }, [wishlistData, wishlistSuccess, wishlistIsError]);

  const {
    data: customerBagData,
    isSuccess: customerBagIsSuccess,
    isLoading: customerBagIsLoading,
    isError: customerBagIsError,
    error: customerBagError,
  } = useGetCustomerBagDataQuery({ user: user }, { skip: !user });

  const {
    data: customerHomeTryOnData,
    isSuccess: customerHomeTryOnDataIsSuccess,
    isLoading: customerHomeTryOnDataIsLoading,
    isError: customerHomeTryOnDataIsError,
    error: customerHomeTryOnDataError,
  } = useGetCustomerHomeTryOnDataQuery({ user: user }, { skip: !user });

  const {
    data: couponData,
    isSuccess: couponDataIsSuccess,
    isLoading: couponDataIsLoading,
    isError: couponDataIsError,
    error: couponDataError,
  } = useGetCouponListQuery({ user: user, couponType: "" }, { skip: !user });

  const findAutoAppliedCouponData = (
    couponArray,
    couponCode,
    minimumAmount
  ) => {
    const data = couponArray?.find((item) => {
      return (
        minimumAmount > item?.minimumAmount &&
        item?.couponCode === couponCode &&
        item
      );
    });
    return data;
  };

  const calculateCartTotalWithoutOffer = () => {
    return customerBagData?.cart?.reduce((total, item) => {
      return total + item?.prices?.originalPrice * item?.quantity;
    }, 0);
  };
  
  useEffect(() => {
    if (customerBagIsSuccess) {
      const data = customerBagData.cart;
      dispatch(setMyBag(data));
      dispatch(
        setOrderDetails({
          couponCode: findAutoAppliedCouponData(
            couponData,
            customerBagData?.appliedCoupon,
            calculateCartTotalWithoutOffer()
          ),
        })
      );
    } else if (customerBagError && user !== null) {
      dispatch(setMyBag([]));
    }
  }, [customerBagData, customerBagIsSuccess, customerBagIsError, couponData]);

  useEffect(() => {
    if (customerHomeTryOnDataIsSuccess) {
      const data = customerHomeTryOnData.inquiryTray;
      dispatch(setHomeTryOn(data));
    } else if (customerHomeTryOnDataIsError && user !== null) {
      dispatch(setMyBag([]));
    }
  }, [
    customerHomeTryOnData,
    customerHomeTryOnDataIsSuccess,
    customerHomeTryOnDataIsError,
  ]);

  const [isRetailerPage, setIsRetailerPage] = useState(false);

  useEffect(() => {
    setIsRetailerPage(false);
  }, [location.pathname]);

  const eyeFramesRef = useRef(null);
  const newArrivalsRef = useRef(null);
  const ourStoreRef = useRef(null);
  const contactUsRef = useRef(null);

  // ===================== CLEAR WISHLIST AND CART ON LOGOUT ===================

  useEffect(() => {
    if (!user) {
      dispatch(setMyBag([]));
      dispatch(setWishlist([]));
      dispatch(setHomeTryOn([]));
    }
  }, [user]);

  // user subscribe email

  const [subscribe] = useSubscribeMutation();
  const subscribeFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async function (values) {
      subscribe({
        body: {
          email: values?.email,
        },
      }).then((res) => {
        if (res?.data) {
          subscribeFormik?.resetForm();
          toast.success(res?.data.message);
        } else {
          console.log(res);
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
    validate: (values) => {
      let errors = {};
      if (!values?.email) {
        errors.email = "Email id is Required!";
      } else if (!isValidEmail(values?.email)) {
        errors.email = "Email is not valid!";
      }
      return errors;
    },
  });

  // ========================== count down timer ==========================
  const navigate = useNavigate();
  const finalDate = new Date("2024-09-12T17:46:00").getTime();
  const [timeLeft, setTimeLeft] = useState(finalDate - new Date().getTime());
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimeLeft(finalDate - new Date().getTime());
  //     setDays(Math.floor(timeLeft / (1000 * 60 * 60 * 24)));
  //     setHours(
  //       Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  //     );
  //     setMinutes(Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)));
  //     setSeconds(Math.floor((timeLeft % (1000 * 60)) / 1000));
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [timeLeft]);

  useEffect(() => {
    if (timeLeft > 0) {
      navigate("/");
    }
  }, [timeLeft]);
  if (timeLeft > 0) {
    return (
      <div
        className="relative flex lg:flex-row emd:flex-col small:flex-col items-end h-[100dvh] w-[100vw] overflow-hidden"
        style={{
          background: `url(${TIMER_PAGE_SKYE})`,
          objectFit: "cover",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute top-2 emd:top-6 right-2 emd:right-6 text-[#fcf8c2] lg:text-8xl emd:text-6xl small:text-xl text-right mont-light">
          GET READY TO <br /> BE NOTICED
        </div>
        <div className="flex flex-col h-full w-full justify-center lg:items-center emd:items-start small:items-center lg:ps-0 emd:ps-10 lm:ps-10 small:ps-0 ms-0">
          <div className="flex flex-col gap-4 emd:gap-4 h-full lg:p-2 emd:p-2 small:px-0 small:p-0 justify-center items-center">
            <img
              className="lg:h-[80px] emd:h-[50px] small:h-[40px] emd:mt-5 lg:mt-0 small:mt-0"
              src="/Netre-logo.svg"
              alt="Netre"
            />
            <div className="text-center lg:text-[40px] emd:text-[20px] lg:my-5 emd:my-3 small:my-2 small:text-2xl">
              India's Premier destination <br />
              for Quality Eyewear
            </div>
            <div className="my-2">
              <Breakpoint large up>
                <FlipClockCountdown
                  to={finalDate}
                  labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                  labelStyle={{
                    fontSize: 18,
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }}
                  digitBlockStyle={{
                    width: 60,
                    height: 80,
                    fontWeight: 500,
                    fontSize: 36,
                    backgroundColor: "#e9d193",
                  }}
                  dividerStyle={{ color: "#ffffff66", height: 1 }}
                  showSeparators={false}
                  duration={0.5}
                  spacing={0}
                >
                  Finished
                </FlipClockCountdown>
              </Breakpoint>
              <Breakpoint medium down>
                <FlipClockCountdown
                  to={finalDate}
                  labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                  labelStyle={{
                    fontSize: 8,
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }}
                  digitBlockStyle={{
                    width: 32,
                    height: 42,
                    fontWeight: 500,
                    fontSize: 24,
                    backgroundColor: "#e9d193",
                  }}
                  dividerStyle={{ color: "#ffffff66", height: 1 }}
                  showSeparators={false}
                  duration={0.5}
                  spacing={0}
                >
                  Finished
                </FlipClockCountdown>
              </Breakpoint>
            </div>
            <div className="text-[#fcf8c2] lg:text-[50px] emd:text-[30px] small:text-[25px] text-center mont-regular my-2">
              SOMETHING AMAZING <br /> IS COMING SOON!
            </div>
            <input
              className="min-w-[290px] border placeholder-secondary placeholder:text-center border-[#ffffffcc] bg-[#ffffff66] px-6 emd:px-8 py-2 emd:py-4 rounded-lg cursor-pointer"
              type="text"
              placeholder="ENTER EMAIL ADDRESS"
              name="email"
              onChange={subscribeFormik.handleChange}
              value={subscribeFormik.values.email}
              onBlur={subscribeFormik.handleBlur}
              errorMsg={subscribeFormik.errors.email}
              touched={subscribeFormik.touched.email}
            />
            {subscribeFormik.errors.email &&
            subscribeFormik.touched &&
            !subscribeFormik.isDisabled ? (
              <span className={`text-xs text-red-600`}>
                {subscribeFormik.errors.email}
              </span>
            ) : (
              <span className={`mb-[0px]`}></span>
            )}
            <div
              className=" border border-[#ffffffcc] bg-[#ffffff66] px-10 emd:px-10 py-2 rounded-lg cursor-pointer"
              onClick={subscribeFormik.handleSubmit}
            >
              REGISTER TO GET 50% OFF <br />
              ON YOUR FIRST PURCHASE
            </div>
          </div>
        </div>
        <img
          src={TIMER_PAGE_GIRL}
          alt=""
          className="lg:w-1/2 emd:w-1/2 small:w-[75%]"
        />
        <ToastContainer
          position="top-center"
          autoClose={500}
          theme="dark"
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          transition={Bounce}
          toastStyle={{
            opacity: 0.9,
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`App ${
          location.pathname.includes("retailer") &&
          location.pathname !== "/become-retailer"
            ? "pt-[68px]"
            : "lg:pt-[140px] emd:pt-[70px] small:pt-[70px]"
        } `}
      >
        {location.pathname.includes("retailer") &&
        location.pathname !== "/become-retailer" ? (
          <RetailerHeader
            eyeFramesRef={eyeFramesRef}
            newArrivalsRef={newArrivalsRef}
            ourStoreRef={ourStoreRef}
            contactUsRef={contactUsRef}
          />
        ) : (
          <Header
            isRetailerPage={isRetailerPage}
            sidebarToggle={sidebarToggle}
            setSidebarToggle={setSidebarToggle}
            headerLabelsData={headerLabelsData}
          />
        )}
        {/* {isRetailerPage ? (
        <RetailerHeader />
      ) : (
        <Header
          isRetailerPage={isRetailerPage}
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        />
      )} */}
        {/* <Header
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        /> */}
        <MyBag setLensStep={setLensStep} blueCutLensIdDataLabel={headerLabelsData} />
        <Sidebar
          sidebarToggle={sidebarToggle}
          setSidebarToggle={setSidebarToggle}
          headerLabelsData={headerLabelsData}
        />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Home blueCutLensIdDataLabel={headerLabelsData} />
              </>
            }
          />
          {/* <Route
          exact
          path="/:retailer"
          element={
            <>
              <Retailer setIsRetailerPage={setIsRetailerPage} />
            </>
          }
        /> */}
          <Route
            exact
            path="/retailer/:retailerName"
            element={
              <>
                <Retailer
                  setIsRetailerPage={setIsRetailerPage}
                  eyeFramesRef={eyeFramesRef}
                  newArrivalsRef={newArrivalsRef}
                  ourStoreRef={ourStoreRef}
                  contactUsRef={contactUsRef}
                />
              </>
            }
          />
          <Route
            exact
            path="/products/:id"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ProductPage blueCutLensIdDataLabel={headerLabelsData} />
              </Suspense>
            }
          />
          <Route
            path="/categories/:name"
            element={
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <Category blueCutLensIdDataLabel={headerLabelsData} />
                </Suspense>
              </>
            }
          />
          <Route
            path="/search/:searchProductParams?"
            element={
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <Category blueCutLensIdDataLabel={headerLabelsData} />
                </Suspense>
              </>
            }
          />
          <Route
            path="/favourite"
            element={
              <>
                <Favorites blueCutLensIdDataLabel={headerLabelsData} />
              </>
            }
          />
          <Route
            path="/shopping-bag"
            element={<ShoppingBag setLensStep={setLensStep} blueCutLensIdDataLabel={headerLabelsData} />}
          />
          <Route path="/profile-details" element={<ProfileDetails />} />
          <Route path="/order-check/:orderId" element={<OrderCheck />} />
          <Route path="/order-placed/:orderId" element={<OrderPlaced blueCutLensIdDataLabel={headerLabelsData} />} />
          <Route path="/order-failed/:orderId" element={<OrderFailed blueCutLensIdDataLabel={headerLabelsData} />} />
          <Route path="/shopping-tray" element={<ShoppingTray />} />
          <Route
            path="/home-tryon"
            element={<HTO />}
          />
          <Route
            path="/accessories"
            element={<Accessories />}
          />
          <Route
            path="/track-order/:trackOrderId/:trackProductSku"
            element={<TrackOrder />}
          />
          <Route
            path="/exchange-order/:exchangeOrderId/:exchangeProductId/:exchangeProductSku"
            element={<ExchangeOrder />}
          />
          <Route
            path="/cancel-order/:cancelOrderId/:cancelProductId"
            element={<CancelOrder />}
          />
          <Route
            path="/return-order/:returnOrderId/:returnProductId/:returnProductSku"
            element={<ReturnOrder />}
          />
          <Route
            path="/shopping-tray/confirm"
            element={
              <>
                <div className="">Confirmation page</div>
              </>
            }
          />

          <Route path="/login" element={<LoginSignup blueCutLensIdDataLabel={headerLabelsData} />} />
          <Route path="/signup" element={<LoginSignup blueCutLensIdDataLabel={headerLabelsData} />} />
          <Route
            path="/lens"
            element={<Lens step={lensStep} setStep={setLensStep} />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/become-retailer" element={<BecomeRetailer />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/exchange-policy" element={<ExchangePolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/become-retailer" &&
        location.pathname.includes("retailer") ? null : (
          <Footer />
        )}

        <ToastContainer
          position="top-center"
          autoClose={500}
          theme="dark"
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          transition={Bounce}
          toastStyle={{
            opacity: 0.9,
          }}
        />
      </div>
    );
  }
}

export default App;

function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
  // scrooll .App to top
  const scrollArea = document.querySelector(".App");
  if (!scrollArea) return;
  scrollArea.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
}

export const PageNotFound = () => {
  return (
    <Link
      to="/"
      style={{
        width: "100%",
        height: "70vh",
        objectFit: "contain",
        display: "block",
      }}
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          verticalAlign: "middle",
          objectFit: "contain",
        }}
        src={PAGE_NOT_FOUND}
        alt="PAGE NOT FOUND"
      />
    </Link>
  );
};

const OrderCheck = () => {
  const { orderId } = useParams();
  const [checkPaymentStatus] = useCheckPaymentStatusMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (orderId) {
      console.log(orderId);
      const body = {
        order_id: orderId,
      };
      checkPaymentStatus({ body: body })
        .then((res) => {
          if (res.data.status == "Placed") {
            navigate(`/order-placed/${orderId}`);
          } else {
            navigate(`/order-failed/${orderId}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orderId]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999999,
        width: "100vw",
        maxWidth: "100%",
        height: "100dvh",
        overflow: "hidden",
      }}
    >
      <Skeleton height={"100%"} />
      <div className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <p>
          Please do not go back or refresh the page. Your payment is being
          processed...
        </p>
      </div>
    </div>
  );
};
